import Image from "next/image";
import { type CSSProperties, type ReactElement } from "react";
import { cn } from "@/utils/class-utils";

const imageMap: Record<
  string,
  { src: string; alt: string; className: string; style: CSSProperties }
> = {
  "^/$": {
    src: "/images/hero-oct.svg",
    alt: "Woman walking her dog",
    className: "z-30 absolute right-0 bottom-[31px]",
    style: {
      width: "auto",
      height: "175px",
    },
  },
  "^/about(/.*)?$": {
    src: "/images/about.svg",
    alt: "Man serenading a cat",
    className: "z-30 absolute right-4 bottom-0",
    style: {
      width: "auto",
      height: "175px",
    },
  },
  "^/\\d+$": {
    src: "/images/product.svg",
    alt: "Woman listening to a bird",
    className: "z-30 absolute right-0 bottom-[2px]",
    style: {
      width: "auto",
      height: "175px",
    },
  },
};

function getImageComponent(
  url: string,
  searchState: boolean | undefined
): ReactElement | null {
  for (const pattern in imageMap) {
    const regex = new RegExp(pattern);
    if (regex.test(url)) {
      const imageProps = imageMap[pattern];
      return (
        <Image
          priority
          src={imageProps.src}
          width={0}
          height={0}
          alt={imageProps.alt}
          className={cn(imageProps.className, searchState && "z-0 opacity-25")}
          style={imageProps.style}
        />
      );
    }
  }
  return null;
}

export default getImageComponent;
