"use client";

import { type ReactElement, type ReactNode, useEffect } from "react";
import { ThemeProvider } from "next-themes";
import { type UserProfile } from "@/types/user-types";
import useStore, { type RFState, useShallow } from "@/zustand/store";

const selector = (state: RFState): Partial<RFState> => ({
  setProfile: state.setProfile,
});

export function Providers({
  profile,
  children,
}: {
  profile?: Promise<UserProfile>;
  children: ReactNode;
}): ReactElement {
  const { setProfile } = useStore(useShallow(selector));
  useEffect(() => {
    void profile?.then((data) => {
      setProfile?.(data);
    });
  }, [profile, setProfile]);
  return (
    <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
      {children}
    </ThemeProvider>
  );
}
