"use client";

import {
  type ReactElement,
  type ReactNode,
  useEffect,
  useState,
  useRef,
} from "react";
import { usePathname } from "next/navigation";
import { MenuMain } from "@/app/menu/menu-main";
import { cn } from "@/utils/class-utils";

export function Main({ children }: { children: ReactNode }): ReactElement {
  const [isHome, setIsHome] = useState(true);
  const pathname = usePathname();
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      setIsHome(pathname === "/");
      isInitialRender.current = false;
    } else {
      setIsHome(pathname === "/");
    }
  }, [pathname]);

  let mainClass = "wrapper min-w-[--min-width]";
  if (isInitialRender.current) {
    mainClass +=
      pathname === "/" ? " mt-[15dvh] pt-[195px]" : " mt-[195px] pt-0";
  } else {
    mainClass += isHome ? " mt-[15dvh] pt-[195px]" : " mt-[195px] pt-0";
  }

  return (
    <main className={cn(mainClass)}>
      {children}
      <MenuMain />
    </main>
  );
}
