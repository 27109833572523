import { type Dispatch, type ReactElement, type SetStateAction } from "react";
import type { FieldValues } from "react-hook-form";
import { FormProvider as Form, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import type { ExistingUser, NewUser } from "@/types/user-types";
import {
  FormField,
  FormItem,
  FormControl,
  FormLabel,
  FormMessage,
} from "@/components/form";
import { Button } from "@/components/button";
import { Input } from "@/components/input";
import { signIn } from "./sign-in";
import { signUp } from "./sign-up";

const newUserSchema = z.object({
  first_name: z.string().min(1, { message: "You must enter a first name." }),
  last_name: z.string().min(1, { message: "You must enter a last name." }),
  email: z
    .string()
    .min(1, { message: "This field must be filled." })
    .email("This is not a valid email."),
  password: z.string().min(4, {
    message: "Password must be at least 4 characters.",
  }),
});

const existingUserSchema = z.object({
  email: z
    .string()
    .min(1, { message: "This field must be filled." })
    .email("This is not a valid email."),
  password: z.string().min(4, {
    message: "Password must be at least 4 characters.",
  }),
});

export function getFormSchema(
  newUser: boolean
): z.ZodType<NewUser> | z.ZodType<ExistingUser> {
  return newUser ? newUserSchema : existingUserSchema;
}

export default function AuthForm({
  newUser,
  setNewUser,
}: {
  newUser: boolean;
  setNewUser: Dispatch<SetStateAction<boolean>>;
}): ReactElement {
  const formSchema = getFormSchema(newUser);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
    },
  });

  function handleAuthFormSubmit(values: ExistingUser | NewUser): void {
    newUser
      ? signUp(values as NewUser).catch(() => {
          throw new Error("User failed to sign in.");
        })
      : signIn(values as ExistingUser).catch(() => {
          throw new Error("User failed to sign in.");
        });
  }

  let buttonText = "Sign In";
  if (newUser) {
    if (form.formState.isSubmitSuccessful) {
      buttonText = "";
    } else {
      buttonText = "Sign Up";
    }
  } else if (form.formState.isSubmitSuccessful) {
    buttonText = "";
  }

  const errorCount = Object.keys(form.formState.errors).length;

  return (
    <Form {...form}>
      <form
        className="h-full w-full max-w-xs flex flex-col items-center top-0 mx-auto"
        onSubmit={(e) => {
          e.preventDefault();
          void form.handleSubmit(handleAuthFormSubmit)().then();
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          width: "100%",
          marginBottom: "1rem",
        }}
      >
        <h1 className="text-xl w-full flex flex-col items-center justify-center text-black">
          {newUser ? (
            <div>Create a New Account</div>
          ) : (
            <div>Sign In to My Account</div>
          )}
        </h1>
        {newUser ? (
          <>
            <FormField
              control={form.control}
              name="first_name"
              render={({ field }: { field: FieldValues }) => (
                <FormItem>
                  <FormLabel className="sr-only">First Name</FormLabel>
                  <FormControl>
                    <Input
                      autoComplete="given-name"
                      placeholder="First Name"
                      type="text"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="last_name"
              render={({ field }: { field: FieldValues }) => (
                <FormItem>
                  <FormLabel className="sr-only">Last Name</FormLabel>
                  <FormControl>
                    <Input
                      autoComplete="family-name"
                      placeholder="Last Name"
                      type="text"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </>
        ) : null}
        <FormField
          control={form.control}
          name="email"
          render={({ field }: { field: FieldValues }) => (
            <FormItem>
              <FormLabel className="sr-only">Email</FormLabel>
              <FormControl>
                <Input
                  autoComplete="off"
                  placeholder="Email"
                  type="email"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }: { field: FieldValues }) => (
            <FormItem>
              <FormLabel className="sr-only">Password</FormLabel>
              <FormControl>
                <Input placeholder="Password" type="password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          disabled={errorCount !== 0}
          loader={form.formState.isSubmitSuccessful}
          size="long"
          type="submit"
          variant={errorCount > 0 ? "disabled" : "default"}
        >
          <span>
            {errorCount > 0
              ? `${String(errorCount)} error(s) found`
              : buttonText}
          </span>
        </Button>
        <Button
          onClick={() => {
            form.clearErrors();
            setNewUser(!newUser);
          }}
          size="long"
          type="button"
          variant="ghost"
        >
          {newUser
            ? "I already have an account!"
            : "Create a new account instead?"}
        </Button>
      </form>
    </Form>
  );
}
