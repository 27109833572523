"use client";

import { type ButtonHTMLAttributes, forwardRef } from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/utils/class-utils";
import Loader from "@/components/loader";

const buttonVariants = cva("button", {
  variants: {
    variant: {
      default: "default",
      ghost: "ghost",
      disabled: "disabled",
    },
    size: {
      default: "h-12 py-4 px-12",
      long: "h-12 w-full p-4",
      sm: "h-10 rounded-md px-4",
      icon: "h-10 w-10",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
});

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loader?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, loader, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <div className="relative flex items-center justify-center min-w-40">
        {loader ? (
          <Loader
            className={cn(
              "absolute",
              !asChild && "top-3.5 left-[calc(50%-0.75rem)]"
            )}
            color="rgb(var(--primary))"
            size={20}
          />
        ) : null}
        <Comp
          className={cn(buttonVariants({ variant, size, className }))}
          disabled={variant === "disabled"}
          ref={ref}
          {...props}
        />
      </div>
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
