"use client";

import {
  type FormEvent,
  type ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { useShallow } from "zustand/react/shallow";
import { usePathname } from "next/navigation";
import {
  Search,
  SearchLoadInner,
  SearchLoadOuter,
  SearchReset,
} from "@/components/icons";
import { cn } from "@/utils/class-utils";
import useStore, { type RFState } from "@/zustand/store";
import { type SearchResponse } from "@/app/search/search-services";
import { SearchShortResults } from "@/app/search/search-short";
import getImageComponent from "@/app/search/search-image";

const selector = (state: RFState): Partial<RFState> => ({
  searchState: state.searchState,
  setSearchState: state.setSearchState,
  setSearchResults: state.setSearchResults,
});

export function SearchMain(): ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { searchState, setSearchState, setSearchResults } = useStore(
    useShallow(selector)
  );
  const [inputValue, setInputValue] = useState("");
  const [searchIcon, setSearchIcon] = useState<ReactElement>(<Search />);
  const pathname = usePathname();

  function handleSearchToggle(): void {
    if (inputValue === "") {
      setSearchResults?.(null);
    } else {
      setQuery("");
      setSearchResults?.(null);
    }
  }

  function setQuery(newQuery: string): void {
    setInputValue(newQuery);
  }

  function handleFormSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
  }

  useEffect(() => {
    if (inputValue === "") {
      setSearchIcon(
        <Search size={24} strokeWidth={2} className="search-icon" />
      );
      setSearchState?.(false);
    } else {
      if (loading) {
        setSearchIcon(
          <div className="w-[28px] h-[28px] relative">
            <div className="absolute w-[29.5px] h-[27.5px] top-0 left-0 animate-spin">
              <SearchLoadOuter
                size={28}
                strokeWidth={2}
                className="search-icon"
              />
            </div>
            <SearchLoadInner
              size={28}
              strokeWidth={2}
              className="search-icon absolute top-0 left-0"
            />
          </div>
        );
      } else {
        setSearchIcon(
          <SearchReset size={28} strokeWidth={2} className="search-icon" />
        );
      }
      setSearchState?.(true);
    }
  }, [inputValue, loading]);

  function debounce(func: (...args: unknown[]) => void, wait: number) {
    let timeout: ReturnType<typeof setTimeout>;
    return (...args: unknown[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, wait);
    };
  }

  const debouncedSearch = debounce((...args: unknown[]) => {
    const query = args[0] as string;
    if (query) {
      void (async () => {
        setLoading(true);
        try {
          const response = await fetch(`/api/search?q=${query}`);
          const data = (await response.json()) as SearchResponse;

          setSearchResults?.(data);
        } catch (error) {
          throw new Error("Error fetching search results:");
        } finally {
          setLoading(false);
        }
      })();
    } else {
      setSearchResults?.(null);
    }
  }, 500);

  useEffect(() => {
    debouncedSearch(inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (!searchState) {
      setInputValue("");
    }
  }, [searchState]);

  return (
    <>
      <div
        className={cn("search-wrapper", loading ? "pending" : "ready", {
          focused: inputValue !== "",
          blurred: inputValue === "",
        })}
      >
        <form
          className="h-12 w-full"
          action=""
          role="search"
          noValidate
          onSubmit={handleFormSubmit}
          onReset={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setQuery("");
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }}
        >
          <input
            id="searchbox"
            ref={inputRef}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            type="search"
            spellCheck={false}
            maxLength={512}
            value={inputValue}
            placeholder="Find pet medication..."
            onChange={(event) => {
              const value = event.currentTarget.value;
              setQuery(value);
            }}
          />
          <button
            id="search-toggle"
            type="button"
            aria-label="Toggle search"
            className="search-toggle"
            onClick={handleSearchToggle}
          >
            {searchIcon}
          </button>
        </form>
        <div
          className={cn(
            "bg-tertiary z-10 border-2 border-t-0 border-primary rounded-b-md",
            !searchState && "hidden"
          )}
        >
          <SearchShortResults />
        </div>
      </div>
      {getImageComponent(pathname, searchState)}
    </>
  );
}
