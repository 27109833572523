"use client";

import { type ReactElement, useEffect, useState, useRef } from "react";
import Image from "next/image";
import { usePathname } from "next/navigation";
import Link from "next/link";
import { cn } from "@/utils/class-utils";
import { SearchMain } from "@/app/search/search-main";

const contentMap = new Map<string, { title1: string; title2?: string }>([
  ["/", { title1: "Designed by Vets.", title2: "Made for Pets." }],
  ["/about/what-we-do", { title1: "What We Do." }],
  ["/about/who-we-are", { title1: "Who We Are." }],
  ["/about/why-trust-us", { title1: "Why Trust Us." }],
]);

const regexContentMap = [
  { regex: /^\/\d+$/, content: { title1: "Product Details" } },
];

export function Header(): ReactElement {
  const [isHome, setIsHome] = useState(true);
  const pathname = usePathname();
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      setIsHome(pathname === "/");
      isInitialRender.current = false;
    } else {
      setIsHome(pathname === "/");
    }
  }, [pathname]);

  let content = contentMap.get(pathname) ??
    contentMap.get("/") ?? { title1: "Default Title" };
  for (const { regex, content: regexContent } of regexContentMap) {
    if (regex.test(pathname)) {
      content = regexContent;
      break;
    }
  }

  let headerClass: string;
  if (isInitialRender.current) {
    headerClass = pathname === "/" ? "home" : "nothome";
  } else {
    headerClass = isHome ? "home" : "nothome";
  }

  return (
    <header className={cn(headerClass)}>
      <div className="wrapper flex flex-col justify-end min-w-[--min-width]">
        <div className="w-full flex flex-row items-start">
          <div className="pb-16 flex-1">
            <div className="top-0 logotype text-primary">
              <Link href="/" className="logotype mb-2">
                <Image
                  priority
                  src="/images/logo.svg"
                  width={0}
                  height={0}
                  alt="Petscribers logo"
                  className="-translate-y-0.5"
                  style={{
                    width: "35px",
                    height: "35px",
                  }}
                />
                <span className="text-primary text-3xl ml-2">Petscribers</span>
              </Link>
            </div>
            <div>
              <h1 className="text-xl leading-7 text-foreground mt-2 whitespace-nowrap">
                {content.title1}
              </h1>
              {content.title2 ? (
                <h1 className="text-xl leading-7 text-foreground mb-2 whitespace-nowrap">
                  {content.title2}
                </h1>
              ) : null}
            </div>
          </div>
        </div>
        <SearchMain />
      </div>
    </header>
  );
}
