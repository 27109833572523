import { useEffect } from "react";
import type { ReactElement } from "react";
import { cn } from "@/utils/class-utils";

export default function Loader({
  className,
  color,
  size,
}: {
  className: string;
  color: string;
  size: number;
}): ReactElement {
  useEffect(() => {
    async function getLoader(): Promise<void> {
      const { tailChase } = await import("ldrs");
      tailChase.register();
    }
    void getLoader();
  }, []);

  return (
    <div className={cn(className)}>
      <l-tail-chase color={color} size={size} speed="1.5" />
    </div>
  );
}
