"use client";

import { type ReactElement, useEffect, useState } from "react";
import { ArrowLeft, Menu, Close } from "@/components/icons";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "@/components/sheet";
import AuthForm from "@/app/auth/auth-form";
import useStore, { type RFState, useShallow } from "@/zustand/store";
import { menuItems } from "@/app/menu/menu-items";
import { MenuList } from "./menu-list";

const selector = (state: RFState): Partial<RFState> => ({
  profile: state.profile,
  showMenu: state.showMenu,
  setShowMenu: state.setShowMenu,
});

export function MenuMain(): ReactElement {
  const { profile, showMenu, setShowMenu } = useStore(useShallow(selector));
  const [showAuth, setShowAuth] = useState<boolean>(false);
  const [newUser, setNewUser] = useState<boolean>(false);
  const [menuIcon, setMenuIcon] = useState<ReactElement>(
    <Menu size={28} strokeWidth={2} />
  );

  useEffect(() => {
    if (!showMenu) {
      setMenuIcon(<Menu size={28} strokeWidth={2} className="text-primary" />);
      setShowAuth(false);
    }
    if (showMenu)
      setMenuIcon(<Close size={28} strokeWidth={2} className="text-primary" />);
    if (showAuth)
      setMenuIcon(
        <ArrowLeft size={28} strokeWidth={2} className="text-primary" />
      );
  }, [showMenu, showAuth]);

  useEffect(() => {
    if (profile) setShowAuth(false);
  }, [profile]);

  return (
    <Sheet
      onOpenChange={(open: boolean) => {
        if (!showAuth) {
          setShowMenu?.(open);
        }
      }}
      open={showMenu}
    >
      <button
        className="menu-button"
        onClick={() => {
          if (!showAuth) {
            setShowMenu?.(!showMenu);
          } else {
            setShowAuth(!showAuth);
          }
        }}
        type="button"
      >
        {menuIcon}
        <span className="sr-only">{showMenu ? "Close" : "Open"}</span>
      </button>
      <SheetContent className="menu">
        <SheetHeader>
          <SheetTitle className="sr-only">Main menu</SheetTitle>
          <SheetDescription className="sr-only">
            Main menu for Petscribers.
          </SheetDescription>
        </SheetHeader>
        {showAuth ? (
          <AuthForm newUser={newUser} setNewUser={setNewUser} />
        ) : (
          <MenuList
            menuItems={menuItems}
            profile={profile}
            setNewUser={setNewUser}
            setShowAuth={setShowAuth}
          />
        )}
      </SheetContent>
    </Sheet>
  );
}
