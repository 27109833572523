import React, { type ReactElement, useEffect, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import Link from "next/link";
import useStore, { type RFState } from "@/zustand/store";
import { type MedPack, type MedResult } from "@/types/med-types";
import {
  Cat,
  Cattle,
  Dog,
  Horse,
  Other,
  Parrot,
  Pig,
  Poultry,
  Rabbit,
  Sheep,
} from "@/components/icons";
import { cn } from "@/utils/class-utils";

const selector = (state: RFState): Partial<RFState> => ({
  setSearchState: state.setSearchState,
  searchResults: state.searchResults,
});

export function SearchShortResults(): ReactElement {
  const { setSearchState, searchResults } = useStore(useShallow(selector));
  const [items, setItems] = useState<MedResult[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  function simulateHover(id: string): void {
    const medLink = document.getElementById(id);
    if (medLink) {
      medLink.classList.add("focus");
      const mouseOverEvent = new MouseEvent("mouseover", {
        bubbles: true,
        cancelable: true,
        view: window,
      });
      medLink.dispatchEvent(mouseOverEvent);
      medLink.scrollIntoView({ behavior: "smooth", block: "end" });
    } else {
      throw new Error(`Element with selector "${id}" not found.`);
    }
  }

  function clearHover(id: string): void {
    const medLink = document.getElementById(id);
    if (medLink) {
      medLink.classList.remove("focus");
    }
  }

  useEffect(() => {
    items.forEach((item) => {
      clearHover(`med-link-${String(item.token_id)}`);
    });
    setCurrentIndex(0);
    if (items[0]) {
      simulateHover(`med-link-${String(items[0].token_id)}`);
    }
  }, [items]);

  useEffect(() => {
    if (searchResults) {
      setItems(searchResults.hits);
    } else {
      setItems([]);
    }
  }, [searchResults]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === "ArrowUp") {
        setCurrentIndex((prevIndex) => {
          clearHover(`med-link-${String(items[prevIndex].token_id)}`);
          const newIndex = Math.max(prevIndex - 1, 0);
          simulateHover(`med-link-${String(items[newIndex].token_id)}`);
          return newIndex;
        });
      }
      if (event.key === "ArrowDown") {
        setCurrentIndex((prevIndex) => {
          clearHover(`med-link-${String(items[prevIndex].token_id)}`);
          const newIndex = Math.min(prevIndex + 1, items.length - 1);
          simulateHover(`med-link-${String(items[newIndex].token_id)}`);
          return newIndex;
        });
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [items, currentIndex]);

  const speciesToSvgMap: Record<string, ReactElement> = {
    Cats: <Cat />,
    Dogs: <Dog />,
    Rabbits: <Rabbit />,
    Horses: <Horse />,
    Chickens: <Poultry />,
    Turkeys: <Poultry />,
    Cattle: <Cattle />,
    Sheep: <Sheep />,
    Pigs: <Pig />,
    "Ornamental Birds": <Parrot />,
  };

  function getSpeciesSvg(species: string): ReactElement {
    return speciesToSvgMap[species] ?? <Other />;
  }

  return (
    <ol className={cn("z-20 search-short max-h-[30dvh] overflow-y-auto")}>
      {items.length > 0
        ? items.map((hit) => {
            const prioritizedSpecies = ["cat", "dog", "rabbit"];
            const speciesSet = new Set(prioritizedSpecies);
            const prioritized = hit.target_species.filter((species) =>
              speciesSet.has(species)
            );
            const remaining = hit.target_species.filter(
              (species) => !speciesSet.has(species)
            );
            const displayedSpecies = [...prioritized, ...remaining].slice(0, 3);
            const remainingCount =
              hit.target_species.length - displayedSpecies.length;

            return (
              <li
                key={hit.token_id}
                className={`med-type ${
                  hit.med_packs.filter((pack) => pack.availability === "A")
                    .length === 0
                    ? "disabled"
                    : ""
                }`}
              >
                <div>
                  <div className="text-base font-medium mb-1">
                    <span className="mr-1">{hit.name}</span>
                    <span className="mr-0.5">{hit.strength}</span>
                    <span>{hit.concentration_unit}</span>
                  </div>
                </div>
                <div className="flex-1 flex flex-row gap-x-2">
                  <div className="bg-accent rounded-md h-6 pl-4">
                    {hit.med_packs.filter((pack) => pack.availability === "A")
                      .length > 0
                      ? hit.med_packs
                          .filter((pack) => pack.availability === "A")
                          .map((pack: MedPack) => {
                            return (
                              <Link
                                key={pack.pack_id}
                                href={`/${String(hit.token_id)}?pack=${String(
                                  pack.pack_id
                                )}`}
                                className="med-pack no-wrap h-full font-medium"
                                onClick={() => {
                                  setSearchState && setSearchState(false);
                                }}
                              >
                                {pack.item_value} {pack.item_unit}
                              </Link>
                            );
                          })
                      : "This product has no available packs."}
                  </div>
                  <div>
                    {displayedSpecies.map((species: string) => {
                      return (
                        <span
                          key={species}
                          className="inline-block mr-1"
                          aria-label={species}
                        >
                          {getSpeciesSvg(species)}
                        </span>
                      );
                    })}
                    {remainingCount > 0 && (
                      <span className="target-species no-wrap h-full font-medium inline-block -translate-y-1.5">
                        +{remainingCount}
                      </span>
                    )}
                  </div>
                  <Link
                    id={`med-link-${String(hit.token_id)}`}
                    type="button"
                    href={`/${String(hit.token_id)}`}
                    className="med-link"
                    onClick={() => {
                      setSearchState?.(false);
                    }}
                    aria-label={`Show details for ${hit.name}`}
                  />
                </div>
              </li>
            );
          })
        : null}
    </ol>
  );
}
