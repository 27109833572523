import { type Dispatch, type ReactElement, type SetStateAction } from "react";
import type { UserProfile } from "@/types/user-types";
import { signOut } from "@/app/auth/sign-out";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/accordion";
import type { MenuItem } from "@/types/menu-types";

export function MenuList({
  profile,
  menuItems,
  setShowAuth,
  setNewUser,
}: {
  profile: UserProfile | null | undefined;
  menuItems: MenuItem[];
  setShowAuth: Dispatch<SetStateAction<boolean>>;
  setNewUser: Dispatch<SetStateAction<boolean>>;
}): ReactElement {
  function handleSignOut(): void {
    void signOut();
  }
  function isVisible(item: MenuItem): boolean {
    if (item.authRequired && !profile) {
      return false;
    }
    return !(
      item.roleRequired &&
      item.roleRequired !== profile?.role &&
      profile?.role !== "admin"
    );
  }
  return (
    <ul className="menu-list">
      {menuItems.map((item) => {
        if (item.authRequired && !profile) {
          return null;
        }
        if (
          item.roleRequired &&
          item.roleRequired !== profile?.role &&
          profile?.role !== "admin"
        ) {
          return null;
        }
        if (!isVisible(item)) {
          return null;
        }
        if (item.items) {
          const visibleSubItems = item.items.filter(isVisible);
          if (visibleSubItems.length === 0) {
            return null;
          }
        }
        return (
          <li className="mb-2" key={item.id}>
            {item.items ? (
              <Accordion collapsible type="single">
                <AccordionItem value={item.id}>
                  <AccordionTrigger className="text-xl">
                    <h3>{item.label}</h3>
                  </AccordionTrigger>
                  <AccordionContent>
                    <ul>
                      {item.items.map((subItem) => {
                        if (
                          subItem.roleRequired &&
                          subItem.roleRequired !== profile?.role &&
                          profile?.role !== "admin"
                        ) {
                          return null;
                        }
                        return (
                          <li key={subItem.id}>
                            <a className="menu-item" href={subItem.href}>
                              <h3>{subItem.label}</h3>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            ) : (
              <a className="menu-item" href={item.href}>
                <h3 className="ml-6 text-xl py-2">{item.label}</h3>
              </a>
            )}
          </li>
        );
      })}
      <li className="menu-footer">
        {profile ? (
          <button
            className="menu-item ml-6 font-medium"
            onClick={(event) => {
              event.preventDefault();
              handleSignOut();
            }}
            type="button"
          >
            Sign Out
          </button>
        ) : (
          <>
            <button
              className="ml-6 font-medium"
              onClick={() => {
                setShowAuth(true);
                setNewUser(false);
              }}
              type="button"
            >
              Sign In
            </button>
            <span className="text-secondary mx-4 flex items-center font-medium text-lg">
              •
            </span>
            <button
              className="font-medium"
              onClick={() => {
                setShowAuth(true);
                setNewUser(true);
              }}
              type="button"
            >
              Join Us
            </button>
          </>
        )}
      </li>
    </ul>
  );
}
