import { forwardRef, createContext, useContext, useId } from "react";
import { Slot } from "@radix-ui/react-slot";
import { Controller, useFormContext } from "react-hook-form";
import type {
  ComponentPropsWithoutRef,
  ElementRef,
  ReactElement,
  HTMLAttributes,
} from "react";
import type {
  ControllerProps,
  FieldPath,
  FieldValues,
  FieldError,
} from "react-hook-form";
import type * as LabelPrimitive from "@radix-ui/react-label";
import { cn } from "@/utils/class-utils";
import { Label } from "./label";

interface FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  name: TName;
}

interface UseFormFieldReturnType {
  id: string;
  name: string;
  formItemId: string;
  formDescriptionId: string;
  formMessageId: string;
  error?: FieldError;
}

interface FormItemContextValue {
  id: string;
}

type FormLabelProps = ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & {
  srOnly?: boolean;
};

const FormFieldContext = createContext<FormFieldContextValue>(
  {} as FormFieldContextValue
);
const FormItemContext = createContext<FormItemContextValue>(
  {} as FormItemContextValue
);

const useFormField = (): UseFormFieldReturnType => {
  const { name } = useContext(FormFieldContext);
  const { id } = useContext(FormItemContext);
  const { getFieldState, formState } = useFormContext();
  const { error } = getFieldState(name, formState);

  return {
    id,
    name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    error,
  };
};

const FormControl = forwardRef<
  ElementRef<typeof Slot>,
  ComponentPropsWithoutRef<typeof Slot>
>((props, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId } =
    useFormField();
  return (
    <Slot
      aria-describedby={
        !error ? formDescriptionId : `${formDescriptionId} ${formMessageId}`
      }
      aria-invalid={Boolean(error)}
      id={formItemId}
      ref={ref}
      {...props}
    />
  );
});
FormControl.displayName = "FormControl";

function FormField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: ControllerProps<TFieldValues, TName>): ReactElement {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  );
}

const FormDescription = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>((props, ref) => {
  const { formDescriptionId } = useFormField();
  return (
    <p
      className={cn("text-sm text-black", props.className)}
      id={formDescriptionId}
      ref={ref}
      {...props}
    />
  );
});
FormDescription.displayName = "FormDescription";

const FormItem = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  (props, ref) => {
    const id = useId();
    return (
      <FormItemContext.Provider value={{ id }}>
        <div className={cn("w-full", props.className)} ref={ref} {...props} />
      </FormItemContext.Provider>
    );
  }
);
FormItem.displayName = "FormItem";

const FormLabel = forwardRef<
  ElementRef<typeof LabelPrimitive.Root>,
  FormLabelProps
>((props, ref) => {
  const { error, formItemId } = useFormField();
  return (
    <Label
      className={cn(
        error && "text-black",
        props.srOnly && "sr-only",
        props.className
      )}
      htmlFor={formItemId}
      ref={ref}
      {...props}
    />
  );
});
FormLabel.displayName = "FormLabel";

const FormMessage = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>((props, ref) => {
  const { error, formMessageId } = useFormField();
  const body = error ? String(error.message) : props.children;
  if (!body) return null;
  return (
    <p
      className={cn("text-sm mt-1 text-black", props.className)}
      id={formMessageId}
      ref={ref}
      {...props}
    >
      {body}
    </p>
  );
});
FormMessage.displayName = "FormMessage";

export {
  FormControl,
  FormField,
  FormDescription,
  FormItem,
  FormLabel,
  FormMessage,
  useFormField,
};
