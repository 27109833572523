import { forwardRef, type InputHTMLAttributes } from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/utils/class-utils";
import { useFormField } from "./form";

const inputVariants = cva("input", {
  variants: {
    variant: {
      default: "default",
      slim: "slim",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

interface InputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, variant, ...props }, ref) => {
    const { error } = useFormField();
    return (
      <input
        className={cn(
          "input",
          variant === "slim" && "slim",
          error && "error",
          className
        )}
        ref={ref}
        type={type}
        {...props}
      />
    );
  }
);
Input.displayName = "Input";

export { Input };
