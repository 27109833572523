import { create } from "zustand";
import { useShallow } from "zustand/react/shallow";
import { type UserProfile } from "@/types/user-types";
import { type SearchResponse } from "@/app/search/search-services";

export interface RFState {
  profile: UserProfile | null;
  setProfile: (profile: UserProfile | null) => void;
  showMenu: boolean;
  setShowMenu: (showMenu: boolean) => void;
  searchState: boolean;
  setSearchState: (searchState: boolean) => void;
  searchResults: SearchResponse | null;
  setSearchResults: (searchResults: SearchResponse | null) => void;
}

const useStore = create<RFState>((set) => ({
  profile: null,
  setProfile: (profile: UserProfile | null) => {
    set({ profile });
  },
  showMenu: false,
  setShowMenu: (showMenu: boolean) => {
    set({ showMenu });
  },
  searchState: false,
  setSearchState: (searchState: boolean) => {
    set({ searchState });
  },
  searchResults: null,
  setSearchResults: (searchResults: SearchResponse | null) => {
    set({ searchResults });
  },
}));

export default useStore;
export { useShallow };
