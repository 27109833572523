import { type MenuItem } from "@/types/menu-types";

export const menuItems: MenuItem[] = [
  {
    id: "profile",
    label: "My Profile",
    href: "/",
    authRequired: true,
  },
  {
    id: "about",
    label: "About Us",
    items: [
      { id: "what", label: "What We Do", href: "/about/what-we-do" },
      { id: "why", label: "Why Trust Us", href: "/about/why-trust-us" },
      { id: "who", label: "Who We Are", href: "/about/who-we-are" },
    ],
  },
  {
    id: "knowledge",
    label: "Knowledge",
    items: [
      { id: "prescriptions", label: "Prescriptions Explained", href: "/" },
      { id: "medications", label: "Medications Explained", href: "/" },
    ],
  },
  { id: "contact", label: "Contact Us", href: "/" },
];
